import React from 'react'
import BlogEntry from './blog-entry'

const BlogEntries = ({ entries }) => (
  <>
    {entries.map(({ node }) => (
      <BlogEntry post={node} single={false} comments={false} />
    ))}
  </>
)

export default BlogEntries
