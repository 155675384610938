import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import BlogEntries from '../components/blog/blog-entries'

const Tags = ({ data, pageContext }) => {
    return (
        <Layout>
            <div className="site-content">
                <h1>
                    <i className="fas fa-tags" /> {pageContext.tag}
                </h1>
                <h3>Entries: {data.allMarkdownRemark.totalCount}</h3>
                <BlogEntries entries={data.allMarkdownRemark.edges} />
            </div>
        </Layout>
    )
}

export default Tags;

export const query = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            image
            date(formatString: "D MMMM, YYYY")
            tags
          }
          fields {
            slug
          }
          excerpt(pruneLength: 500)
        }
      }
    }
  }
`
